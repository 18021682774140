<template>
  <organisms-hero-push v-bind="sliceData" class="hero-lisa">
    <template v-if="slice?.primary?.module_lisa" #independantFormLisa>
      <template v-if="isNewLisa && lisaModule">
        <molecules-lisa-module
          v-bind="lisaModule"
          :with-wrapper="true"
          class="hero-lisa-module"
          @on-lisa-input="onLisaModuleInput"
          @on-lisa-submit="onLisaSubmit"
        />
      </template>
      <template v-if="!isNewLisa && sliceData">
        <lisa-form-hero :slice="slice" :fetch-slice-data="sliceData" />
      </template>
    </template>
  </organisms-hero-push>
</template>

<script>
import { ref, computed } from 'vue';
import { getSliceComponentProps } from '@prismicio/vue';
import { LISA_CTA } from '@/slices/LisaContent/adapter.new';
import heroAdapter from '@/slices/Hero/adapter';
import translations from '@/mixins/componentTranslations.js';
import {
  useI18n,
  usePrismic,
  useNuxtApp,
  useRoute,
  useAsyncData,
} from '#imports';
import useLisaModule from '@/composables/lisaModule';

export default {
  name: 'Hero',
  mixins: [translations],
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  setup: async (props) => {
    const isNewLisa = ref(null);
    const heroData = ref(null);
    const sliceData = ref(null);
    const route = useRoute();
    const i18n = useI18n();
    const { client: $prismic } = usePrismic();
    const { $enhancedLinkSerializer } = useNuxtApp();

    const {
      onLisaModuleSubmit,
      onLisaModuleQuote,
      onLisaModuleInput,
      initLisaModule,
      lisaModule,
    } = useLisaModule({ i18n });

    const urlParams = route.query;

    const { data } = await useAsyncData(
      `heroData-${props.slice.id}`,
      async () => {
        const heroDataAdapter = await heroAdapter(props.slice, {
          pageData: props.context,
          i18n,
          enhancedLinkSerializer: $enhancedLinkSerializer,
          prismic: $prismic,
        });

        heroData.value = heroDataAdapter;
        isNewLisa.value = heroData?.value?.isNewLisa;

        if (isNewLisa.value) {
          await initLisaModule({
            useLisaTitle: true,
            resetButton: false,
            type: 'hero',
            context: props.context,
            slice: props.slice,
            cta: heroData.value.lisaCTA,
            defaultValues: {
              language: urlParams?.lang,
              destination: urlParams?.dest,
              coverType: urlParams?.type,
              location: urlParams?.loc,
            },
            autoFillWithContext: heroData.value.lisaContextAutofill,
          });
        }

        return {
          heroData: heroData.value,
          lisaModule: lisaModule.value,
        };
      },
    );

    sliceData.value = data.value?.heroData;
    lisaModule.value = data.value?.lisaModule;

    const onLisaSubmit = (event) => {
      return sliceData.value?.lisaCTA === LISA_CTA.QUOTE
        ? onLisaModuleQuote(event)
        : onLisaModuleSubmit(event);
    };

    return {
      isNewLisa: computed(() => isNewLisa),
      sliceData,
      onLisaSubmit,
      onLisaModuleInput,
      lisaModule,
    };
  },
};
</script>
<style lang="scss">
.hero-lisa {
  position: relative;
  z-index: 1;
  .hero-lisa-module {
    position: absolute;
    bottom: 3.5rem;

    .cta {
      margin-top: 0;
    }

    @media #{$mq-mobile-tablet} {
      position: absolute;
      bottom: -248px;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 2.5rem;
    }

    .lisa-filter-dropdown .filter-dropdown-value,
    .lisa-dropdown-container .filter-dropdown-choice {
      color: $color-black;
      padding-bottom: april-rem(1);
      margin-top: april-rem(1);
      &.filter-dropdown-placeholder {
        color: $color-medium-gray;
      }
    }

    .filter-dropdown-trigger .ui-icon,
    .input-label-content .input-label-reset .ui-icon {
      color: $color-black;
    }

    .input-label-content label {
      transition: none;
    }

    .input-autocomplete .input-autocomplete-content {
      border: april-rem(1) solid $color-medium-gray;

      &.-placeholder,
      &.-filled {
        border: april-rem(1) solid $color-medium;
        label {
          color: $color-medium;
        }
      }

      label {
        color: $color-bold-gray;
      }
    }
  }
}
</style>
